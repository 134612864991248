<script setup lang="ts">
import Section from '@/components/PDF/Section.vue'
import Page from '@/components/PDF/Page.vue'
import Document from '@/components/PDF/Document.vue'
import Heading from '@/components/PDF/Heading.vue'
import LegsTable from '@/components/PDF/LegsTable.vue'
import HeadingAddress from '@/components/PDF/HeadingAddress.vue'
import illustration from '~/images/pdf/quotation-illustration.png'
import { useProperty } from '@/composables/useProperty'
import BookingData = Domain.BookingCaptainJet.Data.BookingData
import QuotationData = Domain.BookingCaptainJet.Data.QuotationData
import PdfUserData = SupportCaptainJet.Data.PdfUserData
import AircraftData = Domain.AirportCaptainJet.Data.AircraftData
import AircraftAmenity = Domain.BookingCaptainJet.Enums.AircraftAmenity

const $props = defineProps<{
	booking: BookingData
	quotations: QuotationData[]
	overridenUserName?: string
	user: PdfUserData
}>()

const company = useProperty('captainjet.company')
const iosDownloadLink = useProperty('security.features.ios_download_link')
const brand = computed(() => $props.user?.is_business_getter ? $props.user.business_name : 'CaptainJet')

function pictures(aircraft?: AircraftData | null) {
	const aircraftPictures = aircraft?.pictures?.filter((picture) => picture.is_selected) ?? []
	const typePictures = aircraft?.aircraft_type?.pictures?.filter((picture) => picture.is_selected) ?? []

	return (['EXTERIOR', 'INTERIOR', 'FLOORPLAN'])
		.map((type) => aircraftPictures.find((picture) => picture.type === type)?.url ?? typePictures.find((picture) => picture.type === type)?.url)
		.filter(Boolean) as string[]
}

function leg(quotation: QuotationData) {
	return quotation.legs?.at(0)
}

function hasAmenity(
	quotation: QuotationData,
	amenities?: AircraftAmenity[] | null,
) {
	if (!amenities || !amenities.length) {
		return leg(quotation)?.amenities?.length
	}

	return amenities.some((amenity) =>
		leg(quotation)?.amenities?.includes(amenity),
	)
}
</script>


			<script lang="ts">
			import layout from '@/layouts/pdf.vue'
			export default { layout }
			</script>
			<template>
		
	<Document title="Flight request inquiry" :user="user">
		<Heading>
			<HeadingAddress
				label="To"
				:title="overridenUserName ?? booking.user?.full_name"
			/>
			<HeadingAddress
				label="From"
				:title="user?.is_business_getter ? user.business_name : company.name"
			>
				<template v-if="user?.is_business_getter">
					{{ user.business_address }}
				</template>
				<template v-else>
					{{ company.address }} <br />
					{{ company.postal_code }} {{ company.city }}<br />
					{{ company.country }} <br />
				</template>
			</HeadingAddress>
		</Heading>

		<Section>
			<p>Dear {{ overridenUserName ?? booking.user?.full_name }},</p>
			<p>
				Thank you very much for your inquiry. You will find
				attached a detailed offer for your flight request. This
				charter offer is subject to landing permission, traffic
				rights, airports slots and aircraft and crew
				availabilities.
			</p>
			<p>
				The selected aircraft are currently still available. The
				{{ brand }} team is here to help you. Do not hesitate to
				contact our team of professionals if you have any
				question.
			</p>
			<p>
				Truly yours,
				<span class="block font-medium">{{ brand }}'s team</span>
			</p>
		</Section>
		<Section title="Offers">
			<div class="grid grid-cols-3 gap-4">
				<div v-for="(quotation, index) in quotations" :key="index" class="text-brand relative rounded-lg bg-turquoise-50 pb-3 pt-1 text-sm font-medium">
					<div class="flex justify-between">
						<p class="text-md px-3 pt-2 font-bold tracking-tight" v-text="quotation.aircraft?.aircraft_type?.name" />
						<div class="text-brand-title pr-2 text-xs">
							N° {{ index + 1 }}
						</div>
					</div>
					<div class="mt-3 flex items-center space-x-4 px-3">
						<div class="flex flex-1 items-center space-x-1.5">
							<IconCabin class="text-brand-title size-5" />
							<span v-if="quotation.aircraft?.aircraft_type?.cabin_volume" class="leading-none">
								{{ quotation.aircraft?.aircraft_type?.cabin_volume }}m<sup>3</sup>
							</span>
							<span v-else class="leading-none">-</span>
						</div>
						<div class="flex flex-1 items-center space-x-1.5">
							<IconCircleDollar class="text-brand-title size-5" />
							<span class="leading-none" v-text="quotation.display_price_formatted" />
						</div>
					</div>
				</div>
			</div>
		</Section>
		<Section v-if="!user?.is_business_getter" headless>
			<div class="flex pt-5">
				<div class="w-64">
					<img
						class="size-full object-contain"
						:src="illustration"
						alt="What makes us different?"
					/>
				</div>
				<div class="ml-8 flex flex-1 flex-col justify-center text-left">
					<div class="text-brand font-display text-2xl font-bold tracking-wide">
						What makes us different?
					</div>
					<div class="mt-5 grid grid-cols-3 gap-8">
						<div>
							<IconSmile class="size-7 text-turquoise-500" />
							<div class="mt-2.5">
								<p class="font-bold uppercase tracking-widest text-turquoise-500">
									Friendly
								</p>
								<p class="text-brand mt-1 text-sm leading-normal tracking-tighter">
									Our team of aviation and technology experts is always at your disposal.
								</p>
							</div>
						</div>
						<div>
							<IconAircraftCircleTrue class="size-7 text-turquoise-500" />
							<div class="mt-2.5">
								<p class="font-bold uppercase tracking-widest text-turquoise-500">
									Reliable
								</p>
								<p class="text-brand mt-1 text-sm leading-normal tracking-tighter">
									We are available around the clock to answer any inquiries and accommodate your flight requests.
								</p>
							</div>
						</div>
						<div>
							<IconMobile class="size-7 text-turquoise-500" />
							<div class="mt-2.5">
								<p class="font-bold uppercase tracking-widest text-turquoise-500">
									Digital
								</p>
								<p class="text-brand mt-1 text-sm leading-normal tracking-tighter">
									Smooth service, innovation and convenience are the hallmarks of our approach to digitizing the private jet travel industry.
								</p>
							</div>
						</div>
					</div>
					<div class="mt-6">
						<a :href="iosDownloadLink">
							<IconDownloadAppStore class="text-brand h-10" />
						</a>
					</div>
				</div>
			</div>
		</Section>

		<Page v-for="(quotation, index) in quotations" :key="quotation.id">
			<div class="flex-1">
				<div class="flex items-center justify-between py-2 font-display">
					<h1 class="text-brand-title pt-5 font-bold uppercase leading-none tracking-widest">
						Option N°{{ index + 1 }}
					</h1>
					<span
						v-if="quotation.reference"
						class="text-brand-title pt-5 font-medium uppercase leading-none"
						v-text="quotation.reference"
					/>
					<!-- TODO Add tag -->
					<!-- <div class="flex items-center space-x-1 font-medium text-warning-600">
						<IconStarOutline class="h-4 w-4" />
						<span>Top-rated operator</span>
					</div> -->
				</div>

				<section class="flex border-t border-turquoise-100 py-5">
					<div class="flex-1">
						<!-- TODO Add constructor -->
						<!-- <p class="font-medium tracking-tighter text-brand">
							{{ quotation.aircraft?.aircraft_type?.constructor }}
						</p> -->
						<p class="text-brand mt-1 font-display text-[1.625rem] font-bold leading-normal tracking-wide">
							{{ quotation.aircraft?.aircraft_type?.name }}
						</p>

						<!-- TODO Add approval -->
						<!-- <div class="mt-2.5 inline-flex items-center space-x-1 rounded bg-danger-100 py-1 px-2 text-sm text-danger-600">
							<IconAircraftCircleTrue class="h-3 w-3" />
							<p class="font-medium">
								[This aircraft requires owner approval.]
							</p>
						</div> -->
					</div>

					<div class="ml-6 flex justify-between text-left tracking-tighter">
						<table class="text-brand table-fixed font-medium">
							<tr>
								<th class="text-brand-title pr-4 font-medium">
									Category
								</th>
								<td>
									{{ quotation.aircraft?.aircraft_type?.aircraft_category?.name ?? "-" }}
								</td>
							</tr>

							<tr>
								<th class="text-brand-title pr-4 font-medium">
									Manufactured
								</th>
								<td>
									{{ quotation.aircraft?.year_of_manufacture }}
								</td>
							</tr>
							<tr>
								<th class="text-brand-title pr-4 font-medium">
									Refurbished
								</th>
								<td>
									{{ quotation.aircraft?.year_of_refurbishment ?? "-" }}
								</td>
							</tr>
							<tr>
								<th class="text-brand-title pr-4 font-medium">
									Luggage capacity
								</th>
								<td v-if="quotation.aircraft?.aircraft_type?.luggage_compartment_volume">
									{{ quotation.aircraft?.aircraft_type?.luggage_compartment_volume.toFixed(2) }}m<sup>3</sup>
								</td>
								<td v-else>
									-
								</td>
							</tr>
						</table>

						<div class="mx-4 w-px shrink-0 bg-blue-100" />

						<table class="text-brand table-fixed font-medium">
							<tr>
								<th class="text-brand-title pr-4 font-medium">
									Nationality
								</th>
								<td>
									{{ quotation.aircraft?.country?.name }}
								</td>
							</tr>
							<tr v-if="quotation.aircraft?.aircraft_type?.cabin_volume">
								<th class="text-brand-title pr-4 font-medium">
									Cabin volume
								</th>
								<td>
									{{ quotation.aircraft?.aircraft_type?.cabin_volume.toFixed(2) }}m<sup>3</sup>
								</td>
							</tr>
							<tr>
								<th class="text-brand-title pr-4 font-medium">
									Stand-up cabin
								</th>
								<td>
									<span v-if="hasAmenity(quotation, ['stand_up_cabin',])">1.70m and up</span>
									<span v-else>less than 1.70m</span>
								</td>
							</tr>
							<tr>
								<th class="text-brand-title pr-4 font-medium">
									Seating capacity
								</th>
								<td>
									{{ quotation.aircraft?.seats_count ?? "-" }}
								</td>
							</tr>
							<tr v-if="!quotation.aircraft?.aircraft_type?.cabin_volume" class="h-6" />
						</table>
					</div>
				</section>
				<Section v-if="pictures(quotation.aircraft)">
					<!-- <PDFJustifiedLayout :images="pictures(quotation.aircraft)" /> -->
					<PDFImageGrid :images="pictures(quotation.aircraft)" />
				</Section>

				<Section title="Itinerary">
					<LegsTable :legs="quotation.legs ?? []" :user="$props.user" />
					<p>
						All routing and schedule are subject to <span class="font-medium">airport slots, parking, traffic rights
							and permissions.</span> <br />
						Exact tail number will be assigned 24h prior departure.
					</p>
				</Section>

				<Section title="Flight price">
					<template #title-end>
						<p class="text-brand text-lg font-bold tracking-wide">
							{{ quotation.display_price_formatted }}
						</p>
					</template>
					<p>
						This price excludes potential de-icing, repositioning fees,
						ground transportation, internet consumption or any special
						request.
					</p>
				</Section>

				<Section v-if="hasAmenity(quotation)" title="Amenities">
					<div class="text-brand-title grid grid-cols-4 gap-2.5 pt-1 font-medium tracking-tighter">
						<div
							v-if="hasAmenity(quotation, ['flight_attendant'])"
							class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
						>
							<div>
								<IconAttendant class="size-5" />
								<p class="mt-1.5">
									Flight attendant
								</p>
							</div>
							<p :class="{ 'text-brand': hasAmenity(quotation, ['flight_attendant']) }" class="mt-2">
								{{ hasAmenity(quotation, ['flight_attendant'])? 'Yes': 'No' }}
							</p>
						</div>
						<div
							v-if="hasAmenity(quotation, ['smoking_allowed','smoking_not_allowed'])"
							class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
						>
							<div>
								<IconCigarette class="size-5" />
								<p class="mt-1.5">
									Smoking allowed
								</p>
							</div>
							<p :class="{ 'text-brand': hasAmenity(quotation, ['smoking_allowed']) }" class="mt-2">
								{{ hasAmenity(quotation, ['smoking_allowed'])? 'Yes': 'No' }}
							</p>
						</div>
						<div
							v-if="hasAmenity(quotation, ['pets_allowed','pets_not_allowed'])"
							class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
						>
							<div>
								<IconPet class="size-5" />
								<p class="mt-1.5">
									Pets allowed
								</p>
							</div>
							<p :class="{ 'text-brand': hasAmenity(quotation, ['pets_allowed',]) }" class="mt-2">
								{{ hasAmenity(quotation, ['pets_allowed'])? 'Yes': 'No' }}
							</p>
						</div>
						<div
							v-if="hasAmenity(quotation, ['lavatory','no_lavatory'])"
							class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
						>
							<div>
								<IconLavatory class="size-5" />
								<p class="mt-1.5">
									Lavatory
								</p>
							</div>
							<p :class="{ 'text-brand': hasAmenity(quotation, ['lavatory',]) }" class="mt-2">
								{{ hasAmenity(quotation, ['lavatory'])? 'Yes': 'No' }}
							</p>
						</div>
						<div
							v-if="hasAmenity(quotation, ['stand_up_cabin', 'no_stand_up_cabin']) "
							class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
						>
							<div>
								<IconCabin class="size-5" />
								<p class="mt-1.5">
									Stand up Cabin
								</p>
							</div>
							<p :class="{ 'text-brand': hasAmenity(quotation, ['stand_up_cabin',]) }" class="mt-2">
								{{ hasAmenity(quotation, ['stand_up_cabin'])? 'Yes': 'No' }}
							</p>
						</div>
						<div
							v-if="hasAmenity(quotation, ['wifi', 'no_wifi'])"
							class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
						>
							<div>
								<IconWifi class="size-5" />
								<p class="mt-1.5">
									Wi-Fi
								</p>
							</div>
							<p :class="{ 'text-brand': hasAmenity(quotation, ['wifi',]) }" class="mt-2">
								{{ hasAmenity(quotation, ['wifi'])? 'Yes': 'No' }}
							</p>
						</div>
					</div>
				</Section>

				<Section v-if="quotation.comment" title="Additional information">
					<p v-text="quotation.comment" />
				</Section>
			</div>
		</Page>
	</Document>
</template>
