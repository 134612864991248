<script setup lang="ts">
import { parseDateTime } from '~/ts/utils/datetime'
import { formatPhoneNumber } from '~/ts/utils/phone'
import Document from '@/components/PDF/Document.vue'
import Section from '@/components/PDF/Section.vue'
import LegsTable from '@/components/PDF/LegsTable.vue'
import AircraftTable from '@/components/PDF/AircraftTable.vue'
import Icon from '@/components/Icon.vue'
import LegData = Domain.BookingCaptainJet.Data.LegData
import AircraftAmenity = Domain.BookingCaptainJet.Enums.AircraftAmenity
import PdfUserData = SupportCaptainJet.Data.PdfUserData
import Flag from '@/components/Icon/Flag.vue'

defineProps<{
	legs: LegData[]
	user: PdfUserData
}>()

function hasAmenity(leg: LegData, amenities: AircraftAmenity[]) {
	return amenities.some((amenity) => leg.amenities?.includes(amenity))
}
</script>


			<script lang="ts">
			import layout from '@/layouts/pdf.vue'
			export default { layout }
			</script>
			<template>
		
	<Document title="Flight briefing" :user="user">
		<section v-for="(leg, i) in legs" :key="i" :class="{ 'break-after-page': i > 1 }">
			<!-- Flight legs -->
			<Section :title="`${leg.departure_airport?.served_city_and_served_city_governing_district_code} > ${leg.arrival_airport?.served_city_and_served_city_governing_district_code}`">
				<!-- Title -->
				<template #title>
					<div class="flex items-center text-lg font-bold">
						<p class="py-2 font-display uppercase tracking-wider">
							<span>{{ leg.departure_airport?.served_city_and_served_city_governing_district_code }} > {{ leg.arrival_airport?.served_city_and_served_city_governing_district_code }}</span>
							<span v-if="leg.flight_number" class="mx-6 border-l" />
							<span v-if="leg.flight_number" v-text="leg.flight_number" />
						</p>
					</div>
				</template>

				<!-- Leg table -->
				<LegsTable :legs="[leg]" :user="$props.user" />
				<AircraftTable
					:aircraft="leg.aircraft!"
					:pets-on-board="leg.pet_count > 0 ? leg.pet_count.toString() : 'No'"
					:luggage-limit="leg.luggage || leg.luggage_limit_contract || '24 kg / passenger'"
				/>
			</Section>

			<!-- Reservation comment -->
			<Section v-if="leg.reservation_comment" title="General comment" class="whitespace-pre-line">
				{{ leg.reservation_comment }}
			</Section>

			<!-- Amenities -->
			<Section v-if="leg.amenities?.length" title="Amenities">
				<div class="text-brand-title grid grid-cols-6 gap-2.5 pt-1 font-medium tracking-tighter">
					<div class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5">
						<div>
							<IconAttendant class="size-5" />
							<p class="mt-1.5">
								Flight attendant
							</p>
						</div>
						<p :class="{ 'text-brand': hasAmenity(leg, ['flight_attendant']) }" class="mt-2">
							{{ hasAmenity(leg, ['flight_attendant']) ? 'Yes': 'No' }}
						</p>
					</div>
					<div
						v-if="hasAmenity(leg, ['smoking_allowed','smoking_not_allowed'])"
						class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
					>
						<div>
							<IconCigarette class="size-5" />
							<p class="mt-1.5">
								Smoking allowed
							</p>
						</div>
						<p :class="{ 'text-brand': hasAmenity(leg, ['smoking_allowed']) }" class="mt-2">
							{{ hasAmenity(leg, ['smoking_allowed'])? 'Yes': 'No' }}
						</p>
					</div>
					<div
						v-if="hasAmenity(leg, ['pets_allowed','pets_not_allowed'])"
						class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
					>
						<div>
							<IconPet class="size-5" />
							<p class="mt-1.5">
								Pets allowed
							</p>
						</div>
						<p :class="{ 'text-brand': hasAmenity(leg, ['pets_allowed',]) }" class="mt-2">
							{{ hasAmenity(leg, ['pets_allowed'])? 'Yes': 'No' }}
						</p>
					</div>
					<div
						v-if="hasAmenity(leg, ['lavatory','no_lavatory'])"
						class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
					>
						<div>
							<IconLavatory class="size-5" />
							<p class="mt-1.5">
								Lavatory
							</p>
						</div>
						<p :class="{ 'text-brand': hasAmenity(leg, ['lavatory',]) }" class="mt-2">
							{{ hasAmenity(leg, ['lavatory'])? 'Yes': 'No' }}
						</p>
					</div>
					<div
						v-if="hasAmenity(leg, ['stand_up_cabin', 'no_stand_up_cabin']) "
						class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
					>
						<div>
							<IconCabin class="size-5" />
							<p class="mt-1.5">
								Stand up Cabin
							</p>
						</div>
						<p :class="{ 'text-brand': hasAmenity(leg, ['stand_up_cabin',]) }" class="mt-2">
							{{ hasAmenity(leg, ['stand_up_cabin'])? 'Yes': 'No' }}
						</p>
					</div>
					<div
						v-if="hasAmenity(leg, ['wifi', 'no_wifi'])"
						class="flex flex-col justify-between rounded bg-turquoise-50 p-3.5 leading-5"
					>
						<div>
							<IconWifi class="size-5" />
							<p class="mt-1.5">
								Wi-Fi
							</p>
						</div>
						<p :class="{ 'text-brand': hasAmenity(leg, ['wifi',]) }" class="mt-2">
							{{ hasAmenity(leg, ['wifi'])? 'Yes': 'No' }}
						</p>
					</div>
				</div>
			</Section>

			<!-- Meeting points -->
			<Section
				v-if="leg.departure_meeting_point_address || leg.departure_meeting_point_phone || leg.arrival_meeting_point_address || leg.arrival_meeting_point_phone"
				title="Meeting points"
				tight
			>
				<!-- Addresses -->
				<div class="grid grid-cols-2 gap-6">
					<div>
						<span class="text-brand-title font-medium">Departure meeting point address</span>
						<PDFMeetingPointAddress
							:address="leg.departure_meeting_point_address"
							:phone="leg.departure_meeting_point_phone"
						/>
					</div>
					<div>
						<span class="text-brand-title font-medium">Arrival meeting point address</span>
						<PDFMeetingPointAddress
							:address="leg.arrival_meeting_point_address"
							:phone="leg.arrival_meeting_point_phone"
						/>
					</div>
				</div>

				<!-- Comments -->
				<div
					v-if="leg.departure_meeting_point_comment || leg.arrival_meeting_point_comment"
					class="grid grid-cols-2 gap-6"
				>
					<div>
						<span class="text-brand-title font-medium">Departure comment</span>
						<p class="whitespace-pre-line" v-text="leg.departure_meeting_point_comment ?? 'None'" />
					</div>
					<div>
						<span class="text-brand-title font-medium">Arrival comment</span>
						<p class="whitespace-pre-line" v-text="leg.arrival_meeting_point_comment ?? 'None'" />
					</div>
				</div>
			</Section>

			<!-- Transfers -->
			<Section
				v-if="leg.departure_transfer_provider || leg.arrival_transfer_provider || leg.departure_transfer_date || leg.departure_transfer_address || leg.arrival_transfer_address || leg.departure_transfer_comment || leg.arrival_transfer_comment"
				title="Transfers"
			>
				<!-- Departure/arrival -->
				<div class="grid grid-cols-2 gap-6">
					<span class="text-brand-title font-medium">Departure</span>
					<span class="text-brand-title font-medium">Arrival</span>
				</div>

				<!-- Transfer providers -->
				<div
					v-if="leg.departure_transfer_provider || leg.arrival_transfer_provider"
					class="grid grid-cols-2 gap-6"
				>
					<div>
						<span class="text-brand font-medium">Transfer provider</span>
						<p class="whitespace-pre-line" v-text="leg.departure_transfer_provider ?? 'None'" />
					</div>
					<div>
						<span class="text-brand font-medium">Transfer provider</span>
						<p class="whitespace-pre-line" v-text="leg.arrival_transfer_provider ?? 'None'" />
					</div>
				</div>

				<!-- Transfer dates -->
				<div
					v-if="leg.departure_transfer_date"
					class="grid grid-cols-2 gap-6"
				>
					<div>
						<span class="text-brand font-medium">Transfer date</span>
						<p v-text="parseDateTime(leg.departure_transfer_date, { ignoreTimezone: true, useTwelveHourClock: $props.user.use_twelve_hour_clock }).datetime" />
					</div>
				</div>

				<!-- Transfer addresses -->
				<div
					v-if="leg.departure_transfer_address || leg.arrival_transfer_address"
					class="grid grid-cols-2 gap-6"
				>
					<div>
						<span class="text-brand font-medium">Transfer address</span>
						<p class="whitespace-pre-line" v-text="leg.departure_transfer_address ?? 'None'" />
					</div>
					<div>
						<span class="text-brand font-medium">Transfer address</span>
						<p class="whitespace-pre-line" v-text="leg.arrival_transfer_address ?? 'None'" />
					</div>
				</div>

				<!-- Transfer comments -->
				<div
					v-if="leg.departure_transfer_comment || leg.arrival_transfer_comment"
					class="grid grid-cols-2 gap-6"
				>
					<div>
						<span class="text-brand font-medium">Transfer comment</span>
						<p class="whitespace-pre-line" v-text="leg.departure_transfer_comment ?? 'None'" />
					</div>
					<div>
						<span class="text-brand font-medium">Transfer comment</span>
						<p class="whitespace-pre-line" v-text="leg.arrival_transfer_comment ?? 'None'" />
					</div>
				</div>
			</Section>

			<!-- Luggages -->
			<Section v-if="leg.luggage || leg.luggage_comment" title="Luggages">
				<div class="grid grid-cols-2 gap-6">
					<div>
						<span class="text-brand-title font-medium">Luggage</span>
						<p class="whitespace-pre-line" v-text="leg.luggage ?? 'None'" />
					</div>
					<div>
						<span class="text-brand-title font-medium">Comment</span>
						<p class="whitespace-pre-line" v-text="leg.luggage_comment ?? 'None'" />
					</div>
				</div>
			</Section>

			<!-- Catering -->
			<Section v-if="leg.catering" title="Catering">
				<p class="whitespace-pre-line" v-text="leg.catering" />
			</Section>

			<!-- Weather -->
			<Section v-if="leg.departure_weather || leg.arrival_weather" title="Weather">
				<!-- Weather note -->
				<div v-if="leg.weather">
					<span class="text-brand-title font-medium">Weather note</span>
					<p class="whitespace-pre-line" v-text="leg.weather" />
				</div>

				<div class="grid grid-cols-2 gap-6">
					<!-- Departure weather -->
					<div>
						<span class="text-brand-title font-medium">Departure weather</span>
						<span v-if="!leg.departure_weather">-</span>
						<div v-if="leg.departure_weather">
							<div class="flex items-center">
								<Icon :name="`weather-${leg.departure_weather?.icon}`" class="text-brand-title mr-2 size-5 translate-y-px" />
								<span class="font-medium">{{ leg.departure_weather?.temperature }}°C</span>
							</div>
							<p class="pb-1 font-normal leading-5">
								{{ leg.departure_weather?.conditions }}
							</p>
						</div>
					</div>

					<!-- Arrival weather -->
					<div>
						<span class="text-brand-title font-medium">Arrival weather</span>
						<span v-if="!leg.arrival_weather">-</span>
						<div v-if="leg.arrival_weather">
							<div class="flex items-center">
								<Icon :name="`weather-${leg.arrival_weather?.icon}`" class="text-brand-title mr-2 size-5 translate-y-px" />
								<span class="font-medium">{{ leg.arrival_weather?.temperature }}°C</span>
							</div>
							<p class="pb-1 font-normal leading-5">
								{{ leg.arrival_weather?.conditions }}
							</p>
						</div>
					</div>
				</div>
			</Section>

			<!-- Passengers -->
			<!-- {{ leg.passengers }} -->
			<Section v-if="leg.passengers?.length" title="Passengers">
				<div class="grid grid-cols-4 gap-2.5">
					<div
						v-for="passenger in leg.passengers.sort((a, b) => (a.order_column ?? 0) - (b.order_column ?? 0))"
						:key="passenger.id"
						class="text-brand flex flex-col rounded bg-turquoise-50 p-3.5"
					>
						<span class="font-medium tracking-tighter" v-text="passenger.full_name" />
						<span
							v-if="passenger.selected_travel_document?.country"
							class="leading-none tracking-tighter"
							v-text="passenger.selected_travel_document.country.name"
						/>
						<div class="mt-2 flex items-center space-x-1.5">
							<Flag
								v-if="passenger.selected_travel_document?.country"
								:code="passenger.selected_travel_document.country.iso_code.toLowerCase()"
								:name="passenger.selected_travel_document.country.iso_code.toLowerCase()"
								class="w-4"
							/>
							<span v-text="passenger.selected_travel_document?.human_readable_type" />
							<div v-if="passenger.selected_travel_document?.last_three" class="pl-3">
								<span class="font-bold">••••</span>
								<span v-text="passenger.selected_travel_document?.last_three" />
							</div>
						</div>
					</div>
				</div>
			</Section>

			<Section v-if="leg.crew_members?.length" title="Crew">
				<div class="grid grid-cols-4 gap-2.5">
					<div
						v-for="crewMember in leg.crew_members"
						:key="crewMember.full_name"
						class="text-brand flex flex-col rounded bg-turquoise-50 p-3.5"
					>
						<span class="font-medium tracking-tighter" v-text="crewMember.full_name" />
						<span class="leading-none tracking-tighter" v-text="crewMember.human_readable_role" />
						<a
							v-if="crewMember.phone"
							:href="`tel:${crewMember.phone.replaceAll(' ', '')}`"
							class="mt-2 leading-none"
							v-text="formatPhoneNumber(crewMember.phone)"
						/>
					</div>
				</div>
			</Section>
		</section>
	</Document>
</template>
