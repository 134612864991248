import dayjs from 'dayjs'

interface ParseDateTimeOptions {
	dateFormat?: string
	timeFormat?: string
	dateTimeFormat?: string
	utc?: boolean
	ignoreTimezone?: boolean
	useTwelveHourClock?: boolean
}

interface ParsedDateTime {
	date: string
	time: string
	datetime: string
}

export function parse(date?: string | number | dayjs.Dayjs | Date | null | undefined) {
	// Note: this function will convert the passed input to the current device's timezone.
	return dayjs(date)
}

export function getReturnDate(previous?: string | Date) {
	if (previous) {
		return dayjs(previous).add(3, 'day')
	}

	return dayjs().add(1, 'day').set('minute', 0)
}

export function parseDateTime(input: string | null | undefined, options?: ParseDateTimeOptions): ParsedDateTime {
	if (!input) {
		return {
			date: '-',
			time: '',
			datetime: '',
		}
	}

	// If the timezone is forced as `ut, we will instruct the Day.js to view this date
	// as a UTC date. That way the formatting on the line below will just work and
	// nothing gets converted. Otherwise it would take the timezone from string.
	const parsed = options?.utc
		? dayjs.utc(input) // View the date as a UTC date already
		: dayjs(input.slice(0, -6)) // Preserve passed timezone as the string part

	const dateFormat = options?.dateFormat ?? 'ddd DD MMM. YY'

	let timeFormat = options?.timeFormat

	if (!timeFormat) {
		if (options?.useTwelveHourClock) {
			timeFormat = options?.ignoreTimezone ? 'hh:mm A' : 'hh:mm A Z'
		} else {
			timeFormat = options?.ignoreTimezone ? 'HH:mm' : 'HH:mm Z'
		}
	}

	const dateTimeFormat = `${dateFormat} [at] ${timeFormat}`

	return {
		date: parsed.format(dateFormat),
		time: parsed.format(timeFormat),
		datetime: parsed.format(dateTimeFormat),
	}
}

export function parseFlightTime(flightTime: number | null | undefined, format?: string) {
	return dayjs.duration(flightTime || 0, 'minutes').format(format ?? 'HH:mm')
}
