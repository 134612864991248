<script setup lang="ts">
import { useAircraftDetailsDialog } from '~/ts/dialog'
import { parseDateTime, parseFlightTime } from '~/ts/utils/datetime'
import { formatNumber } from '~/ts/utils/number'
import Flag from '@/components/Icon/Flag.vue'
import { useProperty } from '@/composables/useProperty'

const { isOpen, close, properties } = useAircraftDetailsDialog()

const hasContent = computed(() => !!properties)
const selectedImage = ref(0)

function askForContract() {
	properties?.value?.onAskForContract?.()
	close()
}

function reviewContract() {
	properties?.value?.onReviewContract?.()
	close()
}

const hasOverrides = computed(() => properties.value?.quotation?.legs?.some((leg) => !!leg.overrides))
const price = computed(() => properties.value?.estimate?.display_price_formatted ?? properties.value?.quotation?.display_price_formatted)

const useTwelveHourClock = useProperty('security.user.use_twelve_hour_clock') ?? false
</script>

<template>
	<BaseDialog
		:show="isOpen"
		title="Offer details"
		title-size="sm"
		size="3xl"
		@close="close()"
	>
		<!-- <BaseButton
			v-if="hasContent"
			variant="blue"
			size="xs"
			class="!absolute top-5 right-16 space-x-1.5 px-3 font-display"
			icon="3D"
			icon-class="w-5 h-5"
			@click="''"
		>
			3D tour
		</BaseButton> -->

		<div v-if="hasContent" class="border-t border-turquoise-100">
			<!-- Images -->
			<div class="px-3.5 py-2.5">
				<div class="relative overflow-hidden rounded-2xl">
					<!-- Aircraft banner -->
					<img
						v-if="properties?.aircraft?.pictures?.[selectedImage]"
						class="h-[415px] w-full object-cover"
						:src="properties?.aircraft.pictures[selectedImage].url"
						alt="Aircraft"
					/>

					<!-- Gallery -->
					<div
						v-if="properties?.aircraft?.pictures && properties?.aircraft.pictures.length > 1"
						class="absolute inset-x-0 bottom-0 z-20 flex items-center justify-end"
					>
						<div class="flex items-end space-x-7 p-5">
							<span class="text-xs font-medium text-white">{{ properties?.aircraft.pictures.length }} pictures</span>
							<div class="flex space-x-5">
								<button
									v-for="(image, i) in properties?.aircraft.pictures"
									:key="i"
									:class="[
										{ 'ring-white focus-visible:ring-offset-1': selectedImage === i },
										{ 'ring-transparent': selectedImage !== i },
										'size-14 overflow-hidden rounded-xl ring-2 focus-visible:ring-white',
									]"
									@click="selectedImage = i"
								>
									<img
										class="size-full transform-gpu object-cover object-center transition-transform duration-150 hover:scale-110"
										:src="image.url"
									/>
								</button>
							</div>
						</div>
					</div>
					<div
						v-if="properties?.aircraft?.pictures && properties?.aircraft.pictures.length > 1"
						class="absolute inset-x-0 bottom-0 z-10 h-32 bg-gradient-to-b from-black/0 to-black"
					/>
				</div>
			</div>

			<!-- Details -->
			<div class="mt-3">
				<!-- Basic properties -->
				<div class="flex border-b border-turquoise-50 px-5 pb-6">
					<div class="flex flex-col font-display">
						<!-- Aircraft name -->
						<span
							class="text-xl font-semibold tracking-tighter text-blue-500"
							v-text="properties?.aircraft?.aircraft_type?.name"
						/>
						<!-- Description -->
						<p
							v-if="properties?.aircraft?.description"
							class="mt-1 text-sm text-blue-300"
							v-text="properties?.aircraft?.description"
						/>
						<!-- Basic information -->
						<div class="mt-5 flex items-end gap-x-6 text-sm font-medium text-blue-300">
							<div v-if="properties?.aircraft?.country?.iso_code" title="Country" class="flex h-full flex-col justify-between">
								<Flag
									:code="properties.aircraft.country.iso_code.toLowerCase()"
									:name="properties.aircraft.country.name"
									class="size-6"
								/>
								<span class="mt-2">{{ properties?.aircraft?.country?.name }}</span>
							</div>
							<div v-if="properties?.aircraft?.seats_count" title="Seats count" class="flex flex-col">
								<IconSeat class="size-6 text-blue-200" />
								<span class="mt-2">{{ properties?.aircraft?.seats_count }} {{ properties?.aircraft?.seats_count === 1 ? 'seat' : 'seats' }}</span>
							</div>
							<div v-if="properties?.aircraft?.year_of_manufacture" title="Year of manufacture" class="flex h-full flex-col justify-between">
								<IconCalendar class="size-6 text-blue-200" />
								<span class="mt-2">{{ properties?.aircraft?.year_of_manufacture }}</span>
							</div>
						</div>
					</div>
				</div>

				<!-- Other properties and price -->
				<div class="flex items-center justify-between px-5 py-4">
					<!-- Other aircraft properties -->
					<div class="flex gap-x-4 py-2 md:gap-x-14">
						<!-- Luggage compartment volume -->
						<div v-if="properties?.aircraft?.aircraft_type?.luggage_compartment_volume" class="flex flex-col items-start space-y-3">
							<IconBagExpand class="size-6 text-blue-200" />
							<div class="space-y-1 font-medium">
								<span class="font-display text-sm text-blue-300">
									Luggage compartment
								</span>
								<p class="tracking-tighter text-blue-500">
									{{ formatNumber(properties?.aircraft?.aircraft_type?.luggage_compartment_volume) }} m<sup>3</sup>
								</p>
							</div>
						</div>
						<!-- Cabin volume -->
						<div v-if="properties?.aircraft?.aircraft_type?.cabin_volume" class="flex flex-col items-start space-y-3">
							<IconCabin class="size-6 text-blue-200" />
							<div class="space-y-1 font-medium">
								<span class="font-display text-sm text-blue-300">
									Cabin
								</span>
								<p class="tracking-tighter text-blue-500">
									{{ formatNumber(properties?.aircraft?.aircraft_type?.cabin_volume) }} m<sup>3</sup>
								</p>
							</div>
						</div>
						<!-- Range -->
						<div v-if="properties?.aircraft?.aircraft_type?.range" class="flex flex-col items-start space-y-3">
							<IconFlightFrom class="size-6 text-blue-200" />
							<div class="space-y-1 font-medium">
								<span class="font-display text-sm text-blue-300">
									Range
								</span>
								<p class="tracking-tighter text-blue-500">
									{{ properties?.aircraft?.aircraft_type?.range }} nm
								</p>
							</div>
						</div>
					</div>

					<!-- Price -->
					<div v-if="!hasOverrides && price" class="space-y-3 rounded-2xl bg-turquoise-50 px-5 py-4">
						<div class="flex flex-col items-center justify-between gap-x-6 gap-y-2 md:flex-row md:gap-y-0">
							<div class="flex items-center space-x-2">
								<IconDocumentCut class="size-6 text-blue-200" />
								<span class="text-sm font-medium text-blue-300">Price</span>
							</div>
							<span class="text-lg font-bold tracking-tighter text-blue-500">{{ price }}</span>
						</div>

						<!-- Ask for contract button -->
						<BaseButton
							v-if="properties?.quotation?.status === 'quoted'"
							variant="primary"
							icon="ChevronRight"
							size="md"
							icon-placement="right"
							icon-class="h-3.5 w-3.5"
							class="space-x-2 px-4"
							text="Ask for contract"
							@click.prevent="askForContract"
						/>

						<!-- Review contract button -->
						<BaseButton
							v-if="properties?.quotation?.status === 'contract_available'"
							variant="warning"
							icon="ChevronRight"
							size="md"
							icon-placement="right"
							icon-class="h-3.5 w-3.5"
							class="space-x-2 px-4"
							text="Review contract"
							@click.prevent="reviewContract"
						/>
					</div>
				</div>
			</div>

			<!-- Overrides -->
			<div class="p-4">
				<div class="overflow-hidden rounded-2xl bg-turquoise-50">
					<!-- Warning -->
					<div v-if="hasOverrides" class="flex items-center bg-danger-100 px-3 py-1.5 text-xs font-medium leading-4 text-danger-500">
						<IconExclamation class="mr-2.5 size-4" />
						<span>Some elements may differ from your request</span>
					</div>

					<!-- Trip details -->
					<div class="flex items-center justify-between">
						<!-- Legs -->
						<ul class="flex flex-col gap-y-8 p-4 pb-3">
							<div
								v-for="leg in properties?.estimate?.legs ?? properties?.quotation?.legs ?? []"
								:key="leg.id"
								class="flex"
							>
								<!-- Departure hour -->
								<div
									class="flex flex-col"
									:class="{
										'gap-y-3': !leg.fuel_stops,
										'gap-y-8': leg.fuel_stops,
									}"
								>
									<span
										class="text-xs font-medium leading-5"
										:class="leg.overrides?.departs_at_local_time ? 'text-red-500' : 'text-blue-500'"
										:title="parseDateTime(leg.departs_at_local_time, { ignoreTimezone: true, useTwelveHourClock: useTwelveHourClock }).datetime"
										v-text="parseDateTime(leg.departs_at_local_time, { ignoreTimezone: true, useTwelveHourClock: useTwelveHourClock }).time"
									/>

									<span
										v-if="!leg.overrides?.departs_at_local_time"
										class="text-xs leading-5 text-blue-500"
										title="Flight time"
										v-text="parseFlightTime(leg.flight_time)"
									/>

									<span
										v-if="!leg.overrides?.departs_at_local_time"
										class="text-xs font-medium leading-5 text-blue-500"
										:title="parseDateTime(leg.arrives_at_local_time, { ignoreTimezone: true, useTwelveHourClock: useTwelveHourClock }).datetime"
										v-text="parseDateTime(leg.arrives_at_local_time, { ignoreTimezone: true, useTwelveHourClock: useTwelveHourClock }).time"
									/>
								</div>
								<!-- Dots -->
								<div class="flex pb-6">
									<div class="relative mx-5 flex flex-1 flex-col items-center justify-between py-0.5">
										<div class="shrink-0 rounded-full bg-warning-500 p-1">
											<div class="rounded-full bg-white p-1" />
										</div>
										<div class="flex-1 border-l-2 border-dashed border-turquoise-300" />
										<div class="shrink-0 rounded-full bg-warning-500 p-1">
											<div class="rounded-full bg-white p-1" />
										</div>
									</div>
								</div>
								<!-- Airports name -->
								<div class="flex flex-col space-y-5 text-blue-300">
									<!-- Departure -->
									<div class="space-y-1">
										<p class="text-lg font-medium leading-5 tracking-tight text-blue-500" v-text="leg.departure_airport?.served_city_and_served_city_governing_district_code" />
										<span
											class="text-sm tracking-tight"
											:class="{ 'line-through': leg.overrides?.departure_airport }"
											v-text="leg.departure_airport?.name"
										/>
										<!-- Overrides -->
										<template v-if="leg.overrides?.departure_airport">
											<span class="mx-2">/</span>
											<span class="text-sm font-medium tracking-tight text-red-400" v-text="leg.overrides?.departure_airport?.name" />
										</template>
									</div>

									<!-- Fuel stops -->
									<div v-if="leg.fuel_stops" class="mt-1 flex items-center text-sm font-medium">
										<IconGasPump class="mr-2 size-4 shrink-0" />
										<span>{{ leg.fuel_stops }} fuel stops</span>
									</div>

									<!-- Arrival -->
									<div class="space-y-1">
										<p class="text-lg font-medium leading-5 tracking-tight text-blue-500" v-text="leg.arrival_airport?.served_city_and_served_city_governing_district_code" />
										<span
											class="text-sm tracking-tight"
											:class="{ 'line-through': leg.overrides?.arrival_airport }"
											v-text="leg.arrival_airport?.name"
										/>
										<!-- Overrides -->
										<template v-if="leg.overrides?.arrival_airport">
											<span class="mx-2">/</span>
											<span class="text-sm font-medium tracking-tight text-red-400" v-text="leg.overrides?.arrival_airport?.name" />
										</template>
									</div>
								</div>
							</div>
						</ul>

						<!-- Price -->
						<div v-if="hasOverrides && price" class="space-y-3 rounded-2xl bg-turquoise-50 px-5 py-4">
							<div class="flex items-center justify-between gap-x-6">
								<div class="flex items-center space-x-2">
									<IconDocumentCut class="size-6 text-blue-200" />
									<span class="text-sm font-medium text-blue-300">Price</span>
								</div>
								<span class="text-lg font-bold tracking-tighter text-blue-500" v-text="price" />
							</div>

							<!-- Ask for contract button -->
							<BaseButton
								v-if="properties?.quotation?.status === 'quoted'"
								variant="primary"
								icon="ChevronRight"
								size="md"
								icon-placement="right"
								icon-class="h-3.5 w-3.5"
								class="space-x-2 px-4"
								text="Ask for contract"
								@click.prevent="askForContract"
							/>

							<!-- Review contract button -->
							<BaseButton
								v-if="properties?.quotation?.status === 'contract_available'"
								variant="warning"
								icon="ChevronRight"
								size="md"
								icon-placement="right"
								icon-class="h-3.5 w-3.5"
								class="space-x-2 px-4"
								text="Review contract"
								@click.prevent="reviewContract"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="h-[500px]">
			<div class="flex size-full flex-col items-center justify-center pb-6">
				<IconAircraftFlying class="w-24 text-gray-300" />
				<span class="-mt-2 font-medium text-gray-400">Sorry, something went wrong.</span>
			</div>
		</div>
	</BaseDialog>
</template>
